import config from "@/config/config";

const dynamicText = {
  insertName(text, nameToInsert) {
    return replaceTags(text, config.dynamicNameTags, nameToInsert);
  }
};

const replaceTags = (text, tags, valueToInsert) => {
  tags.forEach(nameTag => {
    const tag = "\\[" + nameTag + "\\]";
    text = replaceAll(text, tag, valueToInsert);
  });
  return text;
};

const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace);
}

export default dynamicText;