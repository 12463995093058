<template>
  <v-container>
    <v-row align="center" justify="center" style="margin: 4px">
      <div v-if="invalid">
        <v-alert type="error">
          {{ $t("connotFindResponseData") }}
        </v-alert>
      </div>
      <LoadingSpinner v-if="!responseData && !invalid" />
      <div v-if="responseData">
        <h1>{{ $t("messageFrom") }} {{ responseData.Firmanavn }}</h1>
        <p class="mt-4">
          {{ responseData.Mottaker.Navn }},<br />
          {{ responseData.Firmanavn }}
          {{ $t("wantsYourResponse") }}
        </p>
        <v-card>
          <v-card-text>
            <div v-html="parseMessage()"></div>
          </v-card-text>
        </v-card>
        <br />
        <v-card>
          <v-card-title class="justify-center">
            {{ $t("chooseAlt") }}
          </v-card-title>
          <v-card-text>
            <v-layout row wrap class="justify-center">
              <div class="responseBtns">
                <div v-for="resOpt in responseOptions" v-if="responseOptions">
                  <v-btn
                    :x-small="useSmallButtons"
                    @click="setSelectedAlternative(resOpt.number)"
                    block
                    :color="getBtnColor(resOpt.number)"
                    v-if="resOpt.text"
                    class="responseBtn primary"
                    :href="resOpt.link"
                  >
                    <v-icon v-if="resOpt.number === selectedAlt">
                      mdi-check
                    </v-icon>
                    &nbsp;
                    {{ resOpt.text }}
                    <v-icon v-if="resOpt.link">mdi-open-in-new</v-icon>
                  </v-btn>
                  <div v-if="resOpt.link" class="mt-n4" id="resLink">
                    {{ $t("willLeadYouTo") }} {{ resOpt.link }}
                  </div>
                </div>
              </div>
            </v-layout>
            <v-col cols="12" v-if="comment">
              <h2>{{ $t("yourComment") }}</h2>
              {{ comment }}
            </v-col>
            <v-row>
              <v-col cols="12" v-if="missingCommentInfo">
                <v-alert type="error" dense class="mr-6 ml-6 mb-n4">
                  {{ $t("mustChooseResOpt") }}
                </v-alert>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn text outlined small @click="openCommentDialog">
                  {{ commentActionText }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <p class="mt-14">
          <v-alert type="info" outlined dense>
            {{ $t("responsePageInfo", { company: responseData.Firmanavn }) }}
            <a href="https://groupalert.net">GroupAlert</a>
          </v-alert>
        </p>
      </div>
    </v-row>
    <v-snackbar v-model="thankYouSnackbar" bottom>
      {{ $t("thankYou") }}. {{ $t("yourResponseReg") }}.
    </v-snackbar>
    <v-dialog
      v-model="dialogComment"
      v-if="dialogComment"
      :max-width="dialogMaxWidth"
      persistent
    >
      <UserTextInput
        :title="commentActionText"
        :text="$t('responseCommentInfo')"
        :init-input-value="comment"
        v-on:cancel="closeCommentDialog"
        v-on:ok="setComment"
        :max-length="255"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import LoadingSpinner from "../components/misc/LoadingSpinner";
import axios from "axios/index";
import config from "../config/config";
import stringUtil from "@/util/stringUtil";
import alertResponseOptions from "@/util/alertResponseOptions";
import dynamicText from "@/util/dynamicText";
import UserTextInput from "@/components/misc/UserTextInput";

export default {
  name: "ReceiverResponseRegistration",
  components: { UserTextInput, LoadingSpinner },

  data: () => ({
    responseData: null,
    responseOptions: [],
    invalid: false,
    referenceId: null,
    selectedAlt: null,
    apiUrl: null,
    thankYouSnackbar: false,
    dialogComment: false,
    comment: "",
    missingCommentInfo: false,
    dialogMaxWidth: config.dialogWith.medium
  }),

  computed: {
    useSmallButtons() {
      return !!this.responseOptions.find(resOpt => resOpt.text.length > 20);
    },
    commentActionText() {
      if (this.responseData.Respons.Fritekst) {
        return this.$t("editComment");
      } else {
        return (
          this.$t("addComment") + " (" + this.$t("optional").toLowerCase() + ")"
        );
      }
    },
    userHasResponded() {
      return this.responseData.Mottaker.Status === "B";
    }
  },

  created() {
    if (this.$route.query.i) {
      this.referenceId = this.$route.query.i;
      this.fetchResponseData();
    } else {
      this.invalid = true;
    }
  },

  methods: {
    fetchResponseData() {
      this.apiUrl = config.urlV24Api;
      const data = {
        Referanse: this.referenceId
      };
      serverCall(this.apiUrl, "HentBekreftData", data).then(res => {
        if (!res.data.Respons || !res.data.Respons.VarslingId) {
          this.invalid = true;
          return;
        }
        this.responseData = res.data.Respons;
        this.handleLongStrings();
        this.selectedAlt = this.responseData.Respons.SvarAlternativ;
        this.comment = this.responseData.Respons.Fritekst;
        this.responseOptions = alertResponseOptions.parseApiFormat(
          res.data.Respons.Svar
        );
      });
    },
    submitResponse() {
      this.toggleMissingCommentInfo(false);
      const data = {
        Referanse: this.referenceId,
        SvarAlternativ: this.selectedAlt,
        Fritekst: this.comment
      };
      serverCall(this.apiUrl, "LagreBekreftData", data).then(() => {
        this.thankYouSnackbar = true;
        // Will not update state from server here. Since above API method will
        // pass request to a asynchronous event disapatcher. So the data fetched
        // with HentBekreftData will most likely not be updated. So this
        // component holds the state itself when it comes to selected
        // alternative and comment
      });
    },
    getBtnColor(alt) {
      return this.selectedAlt === alt ? "success" : "";
    },
    handleLongStrings() {
      // Check if long string without spaces exits
      const text = this.responseData.Melding;
      const words = text.split(" ");
      const longWords = words.filter(w => w.length > 40);
      if (longWords.length) {
        // We have exprerienced that very long strings, for example
        // https://www.google.com/maps/place/62.4706880946237,6.14811532454724/@62.4706880946237,6.14811532454724,15.5z/data=!3m1!1e3
        // will mess up the view. Beacuse the string has now spaces the view
        // message text vill be wider then the viewport. Zooming out does not work
        // - the text is clipped (at least when i tried this on my phone).
        // I've tried different style hacks, like overflow: scroll. But did not
        // get this to work (probably because of the Vuetify layout). So the
        // solution is to remove the mobile friendly viewport meta tag for long
        // strings
        clearMobileFriendlyMetaTag();
      }
    },
    parseMessage() {
      let text = this.responseData.Melding.replace(
        config.responseUrl,
        "<i>[" + this.$t("linkToThisPage") + "]</i>"
      );
      text = dynamicText.insertName(text, this.responseData.Mottaker.Navn);
      text = stringUtil.urlify(text, true);
      text = stringUtil.covertLineShiftToHtmlBreak(text);
      return text;
    },
    openCommentDialog() {
      if (this.selectedAlt && this.selectedAlt > 0) {
        this.dialogComment = true;
      } else {
        this.toggleMissingCommentInfo(true);
      }
    },
    closeCommentDialog() {
      this.dialogComment = false;
    },
    toggleMissingCommentInfo(state) {
      this.missingCommentInfo = state;
    },
    setSelectedAlternative(selectedAlt) {
      this.selectedAlt = selectedAlt;
      this.submitResponse();
    },
    setComment(userInput) {
      this.comment = userInput;
      this.closeCommentDialog();
      this.submitResponse();
    }
  }
};

const serverCall = (apiUrl, method, data) => {
  // Not using axios common method in api.js since visitors on this page don't
  // need a session and api endpoint (prod/test/failover)
  // is fetched from URL, not from config
  return axios({
    method: "post",
    url: apiUrl + method,
    data: JSON.stringify(data)
  });
};

const clearMobileFriendlyMetaTag = () => {
  const viewport = document.querySelector("meta[name=viewport]");
  viewport.setAttribute("content", "");
};
</script>

<style scoped>
.responseBtn {
  margin-top: 20px;
  margin-bottom: 20px;
}
#resLink {
  max-width: 300px;
}
.responseBtns {
  text-align: center;
}
</style>
